<template>
    <v-form v-if="dataSource" class="wrapperForm">
        
        <div class="create-oth-incd-before-card-first more-per-lab-wrap" v-if="!isViewMode && !readonly">
            <v-chip
                class="person-label immutable-text"
                small
                label
                color="orange lighten-3"
                @click="onAddAdjustmentList"
                v-tooltip.left-center="addListTooltip"
            >
                <v-icon color="#494F59">fas fa-plus</v-icon>
                <div>{{$t("Добавить_лист_согласования")}}</div>
            </v-chip>

            <v-chip
                class="person-label immutable-text"
                small
                label
                color="orange lighten-3"
                @click="onSaveTemplate"
                v-tooltip.left-center="saveParamsTooltip"
                :disabled="!canSaveTemplate"
            >
                <v-icon color="#494F59">fas fa-save</v-icon>
                <div>{{$t("Сохранить_шаблон")}}</div>
            </v-chip>

            <v-chip
                class="person-label immutable-text"
                small
                label
                color="orange lighten-3"
                @click="onSelectTemplate" 
                v-tooltip.left-center="selectParamsTooltip"
            >
                <v-icon color="#494F59">fas fa-vote-yea</v-icon>
                <div>{{$t("Выбрать_шаблон")}}</div>
            </v-chip>
            <!--
            <div
                class="create-other-incard"
                @click="onAddAdjustmentList"
                v-tooltip.left-center="addListTooltip"
            >
                <i class="fas fa-plus"></i>
                {{$t("Добавить_лист_согласования")}}
            </div>

            <div 
                v-if="canSaveTemplate"
                class="create-other-incard"
                @click="onSaveTemplate"
                v-tooltip.left-center="saveParamsTooltip"
            >
                <i class="fas fa-save"></i>
                {{$t("Сохранить_шаблон")}}
            </div>

            <div class="create-other-incard" @click="onSelectTemplate" v-tooltip.left-center="selectParamsTooltip">
                <i class="fas fa-vote-yea"></i>
                {{$t("Выбрать_шаблон")}}
            </div>-->
        
        </div>

        <template v-if="dataSource.Data.Object.Document.ProcessInfo && dataSource.Data.Object.Document.ProcessInfo.Adjustments">

            <v-row v-for="(adjustment, i) in dataSource.Data.Object.Document.ProcessInfo.Adjustments" :key="i" dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>                                
                            <!--Заголовок и кнопки вверх, вних, удалить-->
                            <div class="form-box-title title-with-right-part">
                                <span>{{$t("Лист_согласования")}} № {{i+1}}</span>
                                <div class="twrp-content">
                                    <div v-if="i != 0 && !isViewMode && !readonly" class="twrp-action" @click="onUpList(i)">
                                        <v-icon role="button" small left v-tooltip.left-center="upListTooltip">
                                            fas fa-caret-up
                                        </v-icon>
                                    </div>
                                    <div v-if="i + 1 != dataSource.Data.Object.Document.ProcessInfo.Adjustments.length && !isViewMode && !readonly" class="twrp-action" @click="onDownList(i)">
                                        <v-icon role="button" small left v-tooltip.left-center="downListTooltip">
                                            fas fa-caret-down
                                        </v-icon>
                                    </div>
                                    <div v-if="!isViewMode && !readonly" class="twrp-action" @click="onDeleteList(i)">
                                        <v-icon role="button" x-small left v-tooltip.left-center="deleteListTooltip">
                                            fas fa-times
                                        </v-icon>
                                    </div>                                        
                                </div>
                            </div>                                
                            <!--поле Порядок согласования-->
                            <v-row class="full-width-row" no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Порядок_согласования")}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <div v-if="isViewMode || readonly" class="onlyReadData">
                                        {{ $t(GetAdjustmentTypes.find(x => x.id === adjustment.Parallel).Value) }}
                                    </div>
                                    <v-autocomplete
                                        v-else
                                        :value="adjustment.Parallel"
                                        @input="update({ property: `Data.Object.Document.ProcessInfo.Adjustments[${i}].Parallel`, value: $event})"
                                        :items="GetAdjustmentTypes"
                                        :item-text="item => $refLocale(item, $i18n.locale)"
                                        item-value="id"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row class="full-width-row" no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Согласующие")}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <div class="icon-click-row-group icrg-align-center">
                                        <div v-if="!isViewMode && !readonly" @click="onAdjustersSelect(i)">
                                            <v-icon
                                                small
                                                v-tooltip.left-center="selectAdjustersTooltip"
                                            >
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        <div class="onlyReadData more-per-lab-wrap">                                                
                                            <template v-for="(adjuster, index) in adjustment.AdjustmentIds">
                                                <v-workplace-chip
                                                    :key="adjuster"
                                                    :id="adjuster"
                                                    :name="adjustment.AdjustmentNames[index]"
                                                />
                                                <v-icon
                                                    v-if="!adjustment.Parallel && adjustment.AdjustmentIds.indexOf(adjuster) +1 != adjustment.AdjustmentIds.length"
                                                    :key="index"
                                                    small
                                                >
                                                    fas fa-angle-right
                                                </v-icon>
                                            </template>
                                        </div>
                                    </div>
                                </v-col>                                    
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </template>

        <v-row dense>
            <v-col cols="12" sm="12" md="12" class="section-wrapper">
                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title">{{$t("На_подпись")}}</div>
                        
                        <v-row class="full-width-row" no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{$t("Подписывающий")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="icon-click-row-group icrg-align-center">
                                    <div v-if="!isViewMode && !readonly" @click="onSignerSelect">
                                        <v-icon small v-tooltip.left-center="selectSignerTooltip">
                                            fas fa-edit
                                        </v-icon>
                                    </div>
                                    <div class="onlyReadData">
                                        <v-workplace-chip
                                            v-if="dataSource.Data.Object.Document.ProcessInfo && dataSource.Data.Object.Document.ProcessInfo.SignerWorkplaceId && dataSource.Data.Object.Document.ProcessInfo.SignerWorkplaceId !=='00000000-0000-0000-0000-000000000000'"
                                            :id="dataSource.Data.Object.Document.ProcessInfo.SignerWorkplaceId"
                                            :name="dataSource.Data.Object.Document.ProcessInfo.SignerName"
                                        />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>

        <CreateRouteParamsTemplateDlg ref="CreateRouteParamsTemplateDlgRef"/>
        <SelectRouteParamsTemplateDlg ref="SelectRouteParamsTemplateDlgRef"/>

    </v-form>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import { mapGetters, mapActions } from 'vuex'
import CreateRouteParamsTemplateDlg from '@/components/dialogs/route-params/CreateRouteParamsTemplateDlg'
import SelectRouteParamsTemplateDlg from '@/components/dialogs/route-params/SelectRouteParamsTemplateDlg'

export default {
    name: "RouteParamsTab",
    components: {
        CreateRouteParamsTemplateDlg,
        SelectRouteParamsTemplateDlg
    },
    props: {
        readonly: {
            type: Boolean,
            default: true
        },
        isViewMode: {
            type: Boolean,
            default: true
        },
        isCommon: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('references', ['GetAdjustmentTypes']),
        ...mapGetters('actionsource', { dataSource: 'getDataSource', dataSourceDocumentId: 'getDataSourceDocumentId', dataSourceType: 'getDataSourceType' }),
        signerName() {
            return this.dataSource.Data.Object.Document.ProcessInfo?.SignerName ?? ""
        },
        canSaveTemplate(){
            return this.dataSource.Data.Object.Document.ProcessInfo != null
                && !!this.dataSource.Data.Object.Document.ProcessInfo.SignerWorkplaceId 
                && !!this.dataSource.Data.Object.Document.ProcessInfo.SignerName 
                && (!this.dataSource.Data.Object.Document.ProcessInfo.Adjustments || this.dataSource.Data.Object.Document.ProcessInfo.Adjustments.reduce((acc, val) => {                
                        if (acc)
                            return val.AdjustmentIds.length > 0;
                        return false;
                }, true));
        },
        //#region Tooltips
        selectSignerTooltip() {
            return this.$t("Выбрать_подписывающего_в_маршруте");
        },
        selectAdjustersTooltip() {
            return this.$t("Выбрать_согласующих_в_этом_листе_согласования");
        },
        upListTooltip() {
            return this.$t("Повысить_лист_согласования_в_очереди_текущего_маршрута");
        },  
        downListTooltip() {
            return this.$t("Понизить_лист_согласования_в_очереди_текущего_маршрута");
        },
        deleteListTooltip() {
            return this.$t("Удалить_лист_согласования_из_текущего_маршрута");
        },
        addListTooltip() {
            return this.$t("Добавить_лист_согласования_в_текущий_маршрут");
        },
        saveParamsTooltip() {
            return this.$t("Сохранить_текущий_маршрут_как_шаблон");
        },
        selectParamsTooltip() {
            return this.$t("Выбрать_маршрут_из_сохраненных_шаблонов");
        }
        //#endregion
    },
    data() {
        return {
            
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('actionsource', { update: 'updateDataSource' }),
        initSource() {
            if (this.dataSource.Data.Object.Document.ProcessInfo == null) {
                this.update(
                {
                    property: 'Data.Object.Document.ProcessInfo',
                    value:
                    {
                        __type: 'DocumentProcessInfoExtended:#Avrora.Objects.Modules.Docflow.CommonObjects',
                        is_new_record: true,
                        Adjustments: [],
                        SignerWorkplaceId: '00000000-0000-0000-0000-000000000000',
                        SignerName: ''
                    }
                });
            }
        },
        async onSaveTemplate() {
            try {
               let templateName = await this.$refs.CreateRouteParamsTemplateDlgRef.open();

               let saveResponse = await httpAPI({
                    url: `api/routes/template?name=${templateName}`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        Content: JSON.stringify(
                        {
                            __type: "DocumentProcessInfoExtended:#Avrora.Objects.Modules.Docflow.CommonObjects",
                            is_new_record: true,
                            Adjustments: this.dataSource.Data.Object.Document.ProcessInfo.Adjustments,
                            SignerWorkplaceId: this.dataSource.Data.Object.Document.ProcessInfo.SignerWorkplaceId,
                            SignerName: this.dataSource.Data.Object.Document.ProcessInfo.SignerName
                        })},
                });
                
                if (saveResponse)
                    this.$notify.success(saveResponse.data.message);
            }
            catch (ex) {
                console.log(ex);
            }
        },
        async onSelectTemplate() {
            try {
                let { id, name } = await this.$refs.SelectRouteParamsTemplateDlgRef.open();
                this.setOverlayVisible({ visible: true, text: `${this.$t("Применяю_шаблон")} ${name}...` });

                let response = await httpAPI({
                    url: `api/routes/template/${id}`,
                    method: 'GET'
                });

                this.initSource();
                
                if (response) {
                    let template = JSON.parse(response.data.payload.object[0][1]);
                    this.update({ property: 'Data.Object.Document.ProcessInfo.Adjustments', value: template.Adjustments });
                    this.update({ property: 'Data.Object.Document.ProcessInfo.SignerWorkplaceId', value: template.SignerWorkplaceId });
                    this.update({ property: 'Data.Object.Document.ProcessInfo.SignerName', value: template.SignerName });
                }
            }
            catch (ex) {
                console.log(ex);
            }
            finally {
                this.setOverlayVisible({ visible: false });
            }
        },
        onAddAdjustmentList() {            
            this.initSource();
            let adjustements = Array.from(this.dataSource.Data.Object.Document.ProcessInfo.Adjustments ?? []);
            adjustements.push({ Parallel: false, AdjustmentIds: [], AdjustmentNames: [] });
            this.update({ property: 'Data.Object.Document.ProcessInfo.Adjustments', value: adjustements });
        },
        async onSignerSelect() {
            try {
                this.initSource();
                let isInnerEnabled = !this.isCommon;
                let signersBoundaries = null;

                if (this.isCommon) {

                    let boundariesResponse = await httpAPI({
                        url: `api/references/signersboundariesenterprises?id=${this.dataSourceDocumentId}`,
                        method: 'GET',
                        headers: { 'isCommon': this.isCommon },
                        skipErrorHandler: true
                    });
                    
                    if (boundariesResponse?.data?.success === true) {
                        // если тут не массив, то документ не имеет ограничений по выбору подписанта
                        if (Array.isArray(boundariesResponse.data.payload?.Data?.Object)) {
                            signersBoundaries = boundariesResponse.data.payload.Data.Object;
                            //если в массиве ограничений присутствует id организации пользователя, разрешаем выбирать подписантов из текущей организации
                            isInnerEnabled = signersBoundaries.includes(this.$store.getters['auth/getUserInfo'].enterpriseId);
                        }
                    }
                    else
                        console.error(`Ошибка получения данных для ограничения доступных подписантов.\n${boundariesResponse?.data?.message}`);
                }

                let selectMemberParams =  { 
                    title: "Выбор_подписанта",
                    includeInner: isInnerEnabled,
                    includeOuter: this.dataSourceType === "OutgoingDocument" && this.isCommon && this.$store.getters['auth/isCommonServiceEnabled'],
                    onlySigners: true,
                    multiple: false,
                    selected: [ this.dataSource.Data.Object.Document.ProcessInfo.SignerWorkplaceId ],
                    implicitExclude: this.dataSource.Data.Object.Document.ProcessInfo.Adjustments?.map( adjustment => ([ ...adjustment.AdjustmentIds ]) ).flat() ?? [],
                    signersBoundaries
                }
                
                let selected = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);                
                this.update({ property: 'Data.Object.Document.ProcessInfo.SignerWorkplaceId', value: selected?.workplaceId });
                this.update({ property: 'Data.Object.Document.ProcessInfo.SignerName', value: selected?.name });
            }
            catch (ex) {
                console.log(ex);
            }
        },
        onDeleteList(currentIndex) {
            let adjustements = Array.from(this.dataSource.Data.Object.Document.ProcessInfo.Adjustments ?? []);
            adjustements.splice(currentIndex, 1);

            if (this.dataSource.Data.Object.Document.ProcessInfo.is_new_record === true &&
                this.dataSource.Data.Object.Document.ProcessInfo.SignerWorkplaceId === "00000000-0000-0000-0000-000000000000" &&
                adjustements.length === 0)
                this.update({ property: 'Data.Object.Document.ProcessInfo', value: null });
            else
                this.update({ property: 'Data.Object.Document.ProcessInfo.Adjustments', value: adjustements });
        },
        async onAdjustersSelect(adjListIndex) {
            try {
                let implicit = this.dataSource.Data.Object.Document.ProcessInfo.Adjustments?.map( adjustment => ([ ...adjustment.AdjustmentIds ]) ).flat() ?? [];
                implicit.push(this.dataSource.Data.Object.Document.ProcessInfo.SignerWorkplaceId);                
                let selectMemberParams =  {
                    title: "Выбор_согласующих",
                    includeInner: true,
                    includeOuter: this.isCommon,
                    multiple: true,
                    selected: this.dataSource.Data.Object.Document.ProcessInfo.Adjustments?.[adjListIndex].AdjustmentIds,
                    implicitExclude: implicit
                }
                let selected = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                this.update({ property: `Data.Object.Document.ProcessInfo.Adjustments[${adjListIndex}].AdjustmentIds`, value: selected.map(x => x.workplaceId) });
                this.update({ property: `Data.Object.Document.ProcessInfo.Adjustments[${adjListIndex}].AdjustmentNames`, value: selected.map(x => x.name) });
            }
            catch (ex) {
                console.log(ex);
            }
        },
        onUpList(currentIndex) {
            let adjustments = Array.from(this.dataSource.Data.Object.Document.ProcessInfo.Adjustments ?? []);
            let curr = adjustments.splice(currentIndex, 1);
            adjustments.splice(currentIndex - 1, 0, curr[0]);
            this.update({ property: `Data.Object.Document.ProcessInfo.Adjustments`, value: adjustments });
        },
        onDownList(currentIndex) {
            let adjustments = Array.from(this.dataSource.Data.Object.Document.ProcessInfo.Adjustments ?? []);
            let curr = adjustments.splice(currentIndex, 1);
            adjustments.splice(currentIndex + 1, 0, curr[0]);
            this.update({ property: `Data.Object.Document.ProcessInfo.Adjustments`, value: adjustments });
        }
    }
}
</script>