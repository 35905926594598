<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card 
                        flat
                    >

                        <v-card-text v-if="loading">
                            <v-col class="text-subtitle-1 text-center" cols="12">
                                {{$t("Загрузка...")}}
                            </v-col>

                            <v-col cols="12">
                                <v-progress-linear
                                    color="#a94442"
                                    indeterminate
                                    rounded
                                    height="6"
                                ></v-progress-linear>
                            </v-col>
                        </v-card-text>

                        <v-card-text v-else>
                            <div class="form-box-title">{{$t("Выберите_шаблон")}}</div>
                            <v-list class="modal-select-list" dense outlined>
                                <v-list-item-group
                                    v-model="selectedItem"
                                    color="cyan darken-1"
                                >
                                    <v-list-item
                                        v-for="(item, i) in dataSource"
                                        :key="i"
                                        :value="item"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item[1]"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>

                    </v-card>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                    color="cyan" 
                    text
                    depressed 
                    @click="save" 
                    v-if="selectedItem">
                        {{$t("Выбрать")}}
                    </v-btn>

                    <v-btn 
                    color="blue-grey" 
                    text 
                    depressed 
                    @click="cancel">
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { httpAPI } from "@/api/httpAPI";

export default {
    name: "SelectRouteParamTemplate",
    data () {
        return {
            title: i18n.t("Шаблоны_параметров_маршрута"),
            visible: false,
            resolve: null,
            reject: null,

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            selectedItem: null,
            dataSource: null,
            loading: false
        }
    },
    methods: {
        async open(options = {})
        {
            this.selectedItem = null;
            this.visible = true;
            this.options = Object.assign(this.options, options);
            this.loading = true;

            let response = await httpAPI({
                url: `api/routes/templates`,
                method: 'GET'
            });

            this.dataSource = response?.data.payload?.object ?? [];
            this.loading = false;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.resolve({ id: this.selectedItem[0], name: this.selectedItem[1] });
        }
    }
}
</script>